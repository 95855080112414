import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Personalizza from "../../components/personalizza";
import SalvaFreschezza from "../../components/salvafreschezza";
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

const SalviettePage = () => {
  const schema = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "Toallitas",
      image: "",
      description:
        "Packaging neutral y cuidado en el detalle, adecuado para cada tipo de situación y actividad. También hacemos toallitas personalizadas para darle un toque original a su local.",
      brand: {
        "@type": "Brand",
        name: "Mettifogo",
      },
    },
  ];

  return (
    <Page
      lang="es"
      title="Toallitas desechables para restaurantes, bares y hoteles"
      description="Packaging neutral y cuidado en el detalle, adecuado para cada tipo de situación y actividad. También hacemos toallitas personalizadas para darle un toque original a su local."
      type="CollectionPage"
      readMore={["/es/contactos/#formulario-contacto", "/es/muestra-gratuita"]}
      image="/toallitas-desechables.jpg"
      schema={schema}
    >
      <Hero
        title="Toallitas desechables"
        description={
          <>
            Packaging neutral y cuidado en el detalle, adecuado para cada tipo
            de situación y actividad. También hacemos toallitas personalizadas
            para darle un toque original a su local.
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia menta"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h2" component="h2">
                Nuestra gama de productos
              </Typography>
              <Typography>
                Desde los aromas cálidos y envolventes de la{" "}
                <strong>canela</strong>, a la frescura del{" "}
                <strong>limón</strong> y el aroma oriental de la mirra.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-limone.jpg"
                alt="Toallita limón"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Limón
            </Typography>
            <Typography>Perfume cítrico</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-lime-e-cannella.jpg"
                alt="Toallita lima y canela"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Lima y canela
            </Typography>
            <Typography>Aroma cítrico y especiado</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-mojito.jpg"
                alt="Toallita mojito"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Mojito
            </Typography>
            <Typography>Aroma fresco y afrutado</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-the-verde.jpg"
                alt="Toallita té verde"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Té verde
            </Typography>
            <Typography>Perfume oriental</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-coloniale.jpg"
                alt="Toallita colonial"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Colonial
            </Typography>
            <Typography>
              Perfume oriental y cítrico
              <br /> (mirra y bergamota)
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-lavanda-camomilla.jpg"
                alt="Toallita lavanda y manzanilla"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Lavanda y manzanilla
            </Typography>
            <Typography>Aroma delicado y relajante</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-sweet-fruit.jpg"
                alt="Toallita sweet fruit"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Sweet fruit
            </Typography>
            <Typography>
              Perfume dulce, afrutado y cítrico
              <br /> (naranja y manzana)
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-personalizzata.jpg"
                alt="Toallita personalizada"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Personalizada
            </Typography>
            <Typography>Elige entre muchos gustos</Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h2" component="h2">
                Características
              </Typography>
              <Typography>
                Nuestros productos han sido diseñados para cumplir con
                diferentes requisitos. Uno de ellos es la{" "}
                <strong>eliminación de olores</strong> desagradables de la piel
                como los del pescado. Al mismo tiempo, son ideales para{" "}
                <strong>refrescar las manos</strong> al final de la comida.
              </Typography>
              <Typography>
                Gracias al <strong>pequeño tamaño del paquete</strong>, se puede
                incluir en la mise en place de su{" "}
                <strong>restaurante o local</strong>. También se pueden poner a{" "}
                <strong>disposición de los clientes</strong> en un cómodo
                contenedor.
              </Typography>
              <Typography>
                Las dimensiones han sido estudiadas para limpiar y refrescar las
                manos de manera eficiente, evitando cualquier desperdicio.
              </Typography>
              <Typography component="div">
                <ul>
                  <li>
                    <strong>Tamaño estándar:</strong> lado largo 10 cm - lado
                    corto 6,6 cm
                  </li>
                  <li>
                    <strong>Tamaño maxi:</strong> lado largo 14 cm - lado corto
                    7,3 cm
                  </li>
                  <li>
                    <strong>Envases:</strong> 500 unids, 900 unids (150 unids x
                    6 paquetes), 1.000 unids
                  </li>
                </ul>
              </Typography>
              <Box mt={2}>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                  <Link
                    type="internal"
                    href="/es/contactos/#contact-form"
                    title="Conoce más sobre solicitar un presupuesto"
                    underline="none"
                  >
                    <Button variant="contained" color="primary" size="large">
                      Solicitar un presupuesto
                    </Button>
                  </Link>
                  <Link
                    type="internal"
                    href="/es/muestra-gratuita"
                    title="Conoce más sobre muestras gratuitas"
                    underline="none"
                  >
                    <Button variant="outlined" color="info" size="large">
                      Solicitar una muestra gratuita
                    </Button>
                  </Link>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <SalvaFreschezza language="es" />

      <Section
        maxWidth="md"
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia menta"
            layout="fullWidth"
          />
        }
      >
        <Stack spacing={2} textAlign="center">
          <Typography variant="h3" component="h2">
            Una amplia selección de fragancias
          </Typography>
          <Typography>
            Cada realidad tiene perfumes que la reflejan, por ejemplo, un
            restaurante de pescado definitivamente prefiere el limón o lima &
            canela; un sunset bar en la orilla del mar tiene como fragancia
            ideal la del mojito; una pequeña y bien cuidada granja es perfecta
            para servir la toallita perfumada colonial (mirra y bergamota).
          </Typography>

          <Box>
            <Link
              type="internal"
              href="/es/muestra-gratuita"
              title="Conoce más sobre muestra gratuita"
              underline="none"
            >
              <Button size="large" variant="contained">
                Prueba nuestros sabores
              </Button>
            </Link>
          </Box>
        </Stack>
      </Section>

      <Personalizza language="es" />
    </Page>
  );
};

export default SalviettePage;
